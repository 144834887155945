/* eslint-disable max-statements */
/* eslint-disable max-lines */
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import keys from 'lodash/keys';
import last from 'lodash/last';
import i18n from 'i18n';
import { createSelector } from 'reselect';
import { formatDate, parseDate } from 'services/DateUtils';

import {
  getFundId,
  getFundName,
  getFundNameByPafId,
  getFundsFormatted,
  getItemsAggregatedByAccount,
  getFormattedCapitalCallOustandingData,
  getStatementsByAccountByFundFormatted,
  getFundsAggregatedBySingleAccount,
  getCapitalCallAggregatedByAccount,
} from 'services/investment_dashboard/fundsHelper';
import {
  assetClassWithHedgeFundBehavior,
  assetClassWithPrivateCapitalBehavior,
  translateName,
} from 'services/investment_dashboard/generalBehaviour';
import { allPcapsWithNonCumulativeValues, allPcapsByYear } from 'services/investment_dashboard/allPcapsFormatter';
import {
  canHaveInvestments,
  isInterestedParty,
} from 'selectors/icnBootstrapSelectors';

// Investment Dashboard permissions
export const canSeeInvestmentDashboardTransactions = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canSeeInvestmentDashboardTransactions',
    false
  )
);
export const canViewAccountAggregateInvestmentDashboard = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canViewAccountAggregateInvestmentDashboard',
    false
  )
);
export const canAccessInvestmentDashboardPdfReport = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canInvestmentDashboardPdfReport',
    false
  )
);
export const wlpInvestmentDashboardLayout = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.white_label_partner.investment_dashboard_layout'
  )
);
export const canViewHedgeFunds = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canViewInvestmentDashboardHedgeFunds',
    false
  )
);
export const canViewPcapCalcsForPrivateCapitalFunds = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canViewInvestmentDashboardPcapCalcsForPc',
    false
  )
);

export const canViewAllTab = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canViewInvestmentDashboardAllTab',
    false
  )
);

export const canViewCurrencySelection = (state) => (
  get(
    state.icnReactBootstrap,
    'icn_react_bootstrap.user.canViewInvestmentDashboardCurrencySelection',
    false
  )
);

// Investment Dashboard root
export const getAdvisors = (state) => state.investmentDashboard.advisors;
export const getInvestors = (state) => state.investmentDashboard.investors;
export const getAccounts = (state) => state.investmentDashboard.accounts;
export const getFunds = (state) => state.investmentDashboard.funds;
export const getSelectedAdvisor = (state) => state.investmentDashboard.selectedAdvisor;
export const getSelectedInvestor = (state) => state.investmentDashboard.selectedInvestor;
export const getSelectedAccounts = (state) => state.investmentDashboard.selectedAccounts;
export const getSelectedFund = (state) => state.investmentDashboard.selectedFund;
export const idFetchingAdvisorsOnGoing =
  (state) => state.investmentDashboard.fetchingAdvisorsOnGoing;
export const idFetchingInvestorsOnGoing =
  (state) => state.investmentDashboard.fetchingInvestorsOnGoing;
export const idFetchingInvestorProfileQueryStringOnGoing =
  (state) => state.investmentDashboard.fetchingInvestorProfileQueryStringOnGoing;
export const idFetchingAccountsOnGoing =
  (state) => state.investmentDashboard.fetchingAccountsOnGoing;
export const idFetchingFundsOnGoing = (state) => state.investmentDashboard.fetchingFundsOnGoing;
export const idConfigs =
  (state) => state.investmentDashboard.config;
export const idLayoutConfig = (state) => state.investmentDashboard.config.layout;
export const idPositiveRedemptionsConfig = (state) => state.investmentDashboard.config.positive_redemptions;
export const idPositiveDistributionsConfig = (state) => state.investmentDashboard.config.positive_distributions;
export const ubsLayout = createSelector(idConfigs, (conf) => conf.layout === 'ubs');
export const idIsDocCenterWidgetCacheDataSetLoaded =
  (state) => state.investmentDashboard.dataSetsLoaded.docCenterWidgetCacheDataSet;
export const idDocCenterWidgetCache = (state) => state.investmentDashboard.docCenterWidgetCache;
export const getSelectedAccountsInvestorProfileQueryString =
  (state) => state.investmentDashboard.investorProfileQueryString;
export const allStatementsInSelectedCurrency =
  (state) => state.investmentDashboard.selectedCurrencyData.allStatements;
export const allStatementsInFundCurrency =
  (state) => state.investmentDashboard.fundCurrencyData.allStatements;
export const getHideAdvisorDropdown = (state) => state.investmentDashboard.hideAdvisorDropdown;
export const statementsByFundInFundCurrency = (state) => state.investmentDashboard.fundCurrencyData.statementsByFund;
export const statementsByFundInSelectedCurrency = (state) => (
  state.investmentDashboard.selectedCurrencyData.statementsByFund
);
export const statementsByAccountInSelectedCurrency = (state) => (
  state.investmentDashboard.selectedCurrencyData.statementsByAccount
);
export const statementsByAccountByFundInFundCurrency = (state) => (
  state.investmentDashboard.fundCurrencyData.statementsByAccountByFund
);
export const getInclusiveFees = (state) => state.investmentDashboard.inclusiveFees;
export const getVerifiedGeneralLedgerTransactions =
  (state) => state.investmentDashboard.verifiedGeneralLedgerTransactions;
export const getSubsequentGeneralLedgerTransactions =
  (state) => state.investmentDashboard.subsequentGeneralLedgerTransactions;
export const getTransactions = (state) => state.investmentDashboard.transactions;
export const getDetailedPcapReport = (state) => state.investmentDashboard.detailedPcapReport;
export const getSelectedCurrencyCode =
  (state) => state.investmentDashboard.selectedCurrencyData.currencyCode;
export const getFundCurrencyCode =
  (state) => state.investmentDashboard.fundCurrencyData.currencyCode;
export const getInvestmentIds = (state) => state.investmentDashboard.investmentsIds;
export const getExternalCommitmentsIds =
  (state) => state.investmentDashboard.externalCommitmentsIds;
export const getActiveTab = (state) => state.investmentDashboard.activeTab;
export const getTabs = (state) => state.investmentDashboard.tabs;
export const getDataSetsLoaded = (state) => state.investmentDashboard.dataSetsLoaded;
export const isUnitizedFund = (state) => get(state.investmentDashboard, 'selectedFund.unitized', false);
export const getFundDataMetrics = (state) => state.investmentDashboard.fundDataMetrics;
export const getCapitalCallOutstanding = (state) => state.investmentDashboard.capitalCallOutstanding;
export const getTotalCommitment = (state) => state.investmentDashboard.selectedCurrencyData.totalCommitment;
export const getAssetClasses = (state) => state.investmentDashboard.assetClasses;
export const getDisclaimers = (state) => state.investmentDashboard.disclaimers;
export const getSelectedAssetClass = (state) => state.investmentDashboard.selectedAssetClass;
export const getFilteredAccounts = (state) => state.investmentDashboard.filteredAccounts;

export const getMostRecentStatementInSelectedCurrency = createSelector(
  allStatementsInSelectedCurrency,
  getTotalCommitment,
  (statements, commitment) => {
    let mostRecentStatement = {};

    if (statements) {
      mostRecentStatement = statements[statements.length - 1];
    } else if (commitment) {
      mostRecentStatement = {
        total_commitment: commitment,
        remaining_commitment: commitment,
        remaining_unfunded_commitment: commitment,
      };
    }

    return mostRecentStatement;
  }
);

export const getAggregationLevel = (state) => state.investmentDashboard.aggregationLevel;

export const isFundLevel = createSelector(
  getAggregationLevel,
  (aggregationLevel) => aggregationLevel === 'fund_level'
);

export const isAccountLevelAggregation = createSelector(
  getAggregationLevel,
  (aggregationLevel) => aggregationLevel === 'account_level'
);

export const isInvestorLevelAggregation = createSelector(
  getAggregationLevel,
  (aggregationLevel) => aggregationLevel === 'investor_level'
);

export const getSelectedFundName = createSelector(
  getFunds,
  getSelectedFund,
  isFundLevel,
  (funds, selectedFund, fundLevel) => {
    if (selectedFund) {
      if (selectedFund.name === 'aggregated_holdings' && fundLevel) {
        return getFundNameByPafId(funds, Number(selectedFund.id));
      }
      return translateName(selectedFund);
    }
    return null;
  }
);

export const hasStatements = createSelector(
  allStatementsInSelectedCurrency,
  (statements) => !isEmpty(statements)
);
export const numStatements = createSelector(allStatementsInSelectedCurrency, (statements) => size(statements));

export const formattedStatementDate = createSelector(
  getMostRecentStatementInSelectedCurrency,
  (investment) => {
    if (investment.quarter_end_date) {
      return formatDate(parseDate(investment.quarter_end_date, 'yyyy-MM-dd'), 'dd-MMM-yyyy');
    }
    return null;
  }
);

export const formattedFundDataMetricsDate = (key) => createSelector(
  getFundDataMetrics,
  (fundDataMetric) => {
    if (fundDataMetric?.[key]) {
      return `(as of ${formatDate(parseDate(fundDataMetric[key], 'yyyy-MM-dd'), 'dd-MMM-yyyy')})`;
    }
    return null;
  }
);

export const factsDate = formattedFundDataMetricsDate('facts_date');
export const highlightsDate = formattedFundDataMetricsDate('highlights_date');
export const overviewDate = formattedFundDataMetricsDate('overview_date');

const hasCashTransactions = (glt) => (
  !isEmpty(glt?.investment_contributions) ||
  !isEmpty(glt?.expense_contributions) ||
  !isEmpty(glt?.recallable_commitment) ||
  !isEmpty(glt?.non_recallable_distributions)
);

export const hasVerifiedTransactions = createSelector(
  getVerifiedGeneralLedgerTransactions,
  hasCashTransactions
);

export const hasSubsequentTransactions = createSelector(
  getSubsequentGeneralLedgerTransactions,
  hasCashTransactions
);

export const hasTransactions = createSelector(
  getTransactions,
  (transactions) => transactions?.transactions_count > 0
);

// selected currency
export const getAllPcapsWithNonCumulativeValues = createSelector(
  allStatementsInSelectedCurrency,
  allPcapsWithNonCumulativeValues
);

// selected currency
export const getAllPcapsByYear = createSelector(
  getAllPcapsWithNonCumulativeValues,
  allPcapsByYear
);

// fund currency
export const getAllPcapsWithNonCumulativeValuesInFundCurrency = createSelector(
  allStatementsInFundCurrency,
  allPcapsWithNonCumulativeValues
);

// fund currency
export const getAllPcapsByYearInFundCurrency = createSelector(
  getAllPcapsWithNonCumulativeValuesInFundCurrency,
  allPcapsByYear
);

// Investment Dashboard Export History
export const getExports = (state) => state.investmentDashboard.exportHistory.exports;
export const exportsSize = (state) => state.investmentDashboard.exportHistory.exports.length;
export const hasExports = createSelector(
  exportsSize,
  (eSize) => (eSize > 0)
);
export const getExportFlyoverStatus = (state) => (
  state.investmentDashboard.exportHistory.exportFlyoverStatus
);
export const getCsvData = (state) => state.investmentDashboard.exportHistory.csvData;

export const hasFundDataMetrics = createSelector(
  getFundDataMetrics,
  (funds) => !isEmpty(funds)
);

export const hasOnlyFundFacts = createSelector(
  hasStatements,
  hasSubsequentTransactions,
  hasFundDataMetrics,
  (statements, subsequentTransactions, fundDataMetrics) => !statements && !subsequentTransactions && fundDataMetrics
);

export const lastTotalCommitmentByPafId = createSelector(
  statementsByFundInSelectedCurrency,
  (pcaps) => {
    const commitmentsByPafId = {};
    keys(pcaps).forEach((key) => {
      const fundPcaps = pcaps[key];
      commitmentsByPafId[key] = last(fundPcaps).total_commitment;
    });
    return commitmentsByPafId;
  }
);

export const getFilteredFunds = createSelector(
  getFunds,
  getSelectedFund,
  canViewHedgeFunds,
  (funds, fund, hedgeFundsPermission) => {
    if (!fund) return [];

    if (!hedgeFundsPermission) return funds;

    if (fund.name !== 'aggregated_holdings') return [fund];

    return funds.filter((f) => fund.id.includes(f.id));
  }
);

export const getFundsWithStatementsData = createSelector(
  isInvestorLevelAggregation,
  statementsByFundInFundCurrency,
  statementsByAccountByFundInFundCurrency,
  getFilteredFunds,
  getSelectedAccounts,
  (investorLevelAggregation, statementsByFund, statementsByAccountByFund, funds, accounts) => {
    if (isEmpty(funds)) {
      return { fundsCount: 0 };
    }
    if (investorLevelAggregation) {
      return getStatementsByAccountByFundFormatted(statementsByAccountByFund, funds);
    }
    const formattedFunds = getFundsFormatted(keys(statementsByFund), funds, getFundName);
    return getFundsAggregatedBySingleAccount(formattedFunds, accounts);
  }
);

export const getCapitalCallOutstandingData = createSelector(
  isInvestorLevelAggregation,
  getCapitalCallOutstanding,
  getFilteredFunds,
  getSelectedAccounts,
  (investorLevelAggregation, capitalCallData, funds, accounts) => {
    if (isEmpty(capitalCallData) || isEmpty(funds)) {
      return { fundsCount: 0 };
    }
    const formattedFunds = getFormattedCapitalCallOustandingData(capitalCallData, funds);
    if (investorLevelAggregation) {
      return getCapitalCallAggregatedByAccount(formattedFunds);
    }
    return getFundsAggregatedBySingleAccount(formattedFunds, accounts);
  }
);

export const getFundsWithMetricsData = createSelector(
  isInvestorLevelAggregation,
  getFundDataMetrics,
  statementsByAccountByFundInFundCurrency,
  getFilteredFunds,
  getSelectedAccounts,
  (investorLevelAggregation, fundDataMetrics, statementsByAccountByFund, funds, accounts) => {
    if (isEmpty(fundDataMetrics) || isEmpty(funds)) {
      return { fundsCount: 0 };
    }
    const fundIds = keys(fundDataMetrics).map((pafId) => getFundId(funds, pafId));
    const formattedFunds = getFundsFormatted(fundIds, funds, getFundName);
    if (investorLevelAggregation) {
      return getItemsAggregatedByAccount(formattedFunds, statementsByAccountByFund);
    }
    return getFundsAggregatedBySingleAccount(formattedFunds, accounts);
  }
);

export const getFundsWithVerifiedTransactionsData = createSelector(
  isInvestorLevelAggregation,
  getVerifiedGeneralLedgerTransactions,
  statementsByAccountByFundInFundCurrency,
  getFilteredFunds,
  getSelectedAccounts,
  (investorLevelAggregation, transactions, statementsByAccountByFund, funds, accounts) => {
    if (isEmpty(transactions) || isEmpty(funds)) {
      return { fundsCount: 0 };
    }
    const formattedFunds = getFundsFormatted(transactions?.fund_ids, funds, getFundName);
    if (investorLevelAggregation) {
      return getItemsAggregatedByAccount(formattedFunds, statementsByAccountByFund);
    }
    return getFundsAggregatedBySingleAccount(formattedFunds, accounts);
  }
);

export const getFundsWithSubsequentTransactionsData = createSelector(
  isInvestorLevelAggregation,
  getSubsequentGeneralLedgerTransactions,
  statementsByAccountByFundInFundCurrency,
  getFilteredFunds,
  getSelectedAccounts,
  (investorLevelAggregation, transactions, statementsByAccountByFund, funds, accounts) => {
    if (isEmpty(transactions) || isEmpty(funds)) {
      return { fundsCount: 0 };
    }
    const formattedFunds = getFundsFormatted(transactions?.fund_ids, funds, getFundName);
    if (investorLevelAggregation) {
      return getItemsAggregatedByAccount(formattedFunds, statementsByAccountByFund);
    }
    return getFundsAggregatedBySingleAccount(formattedFunds, accounts);
  }
);

export const hasHedgeFundBehavior = createSelector(
  getSelectedAssetClass,
  (assetClass) => assetClassWithHedgeFundBehavior(assetClass)
);

export const hasPrivateCapitalBehavior = createSelector(
  getSelectedAssetClass,
  (assetClass) => assetClassWithPrivateCapitalBehavior(assetClass)
);

const hasNoDataToShow = createSelector(
  hasStatements,
  hasSubsequentTransactions,
  hasFundDataMetrics,
  hasHedgeFundBehavior,
  isFundLevel,
  (pcaps, subsequentTransactions, fundDataMetrics, isHedgeFundSelected, isFundLevelSelected) => {
    if (isHedgeFundSelected) {
      return !pcaps && (!fundDataMetrics || (!isFundLevelSelected && fundDataMetrics));
    }

    return !pcaps && !subsequentTransactions && !fundDataMetrics;
  }
);

export const investmentSummaryStatusMessage = createSelector(
  getSelectedAdvisor,
  getSelectedInvestor,
  getSelectedAccounts,
  getSelectedFund,
  canHaveInvestments,
  isInterestedParty,
  getHideAdvisorDropdown,
  hasNoDataToShow,
  idConfigs,
  (advisor, investor, accounts, fund, individualInvestor, interestedParty, hideAdvisorDropdown, showNoDataMessage, configs) => {
    if (!advisor && !individualInvestor && !interestedParty && !hideAdvisorDropdown) {
      return {
        message: i18n.t(
          'investment_dashboard:please_select_an_advisor_investor_account_and_fund_from_the_menu_above'
        ),
        variant: 'extraLargeParagraph',
      };
    }
    if (!investor) {
      return {
        message: i18n.t(
          'investment_dashboard:please_select_an_investor_account_and_fund_from_the_menu_above'
        ),
        variant: 'h4',
      };
    }

    if (!accounts) {
      return {
        message: i18n.t(
          'investment_dashboard:please_select_an_account_and_fund_from_the_menu_above'
        ),
        variant: 'h4',
      };
    }

    if (!fund) {
      return {
        message: i18n.t(
          'investment_dashboard:please_select_a_fund_from_the_menu_above'
        ),
        variant: 'h4',
      };
    }

    if (showNoDataMessage) {
      return {
        message:
          configs.no_data_message ||
          i18n.t('investment_dashboard:there_is_no_data_to_report_on_your_investment_dashboard'),
        variant: 'h4',
      };
    }
    return null;
  }
);

export const isAllAssetClass = createSelector(getSelectedAssetClass, (assetClass) => assetClass === 'all');

export const isAggregatedAccountsSelected = createSelector(
  getSelectedAccounts,
  (selectedAccounts) => selectedAccounts?.name === 'aggregated_accounts'
);

export const showAssetClassFilter = createSelector(
  canViewHedgeFunds,
  getSelectedFund,
  getAssetClasses,
  (userCanViewHedgeFunds, selectedFund, assetClasses) =>
    userCanViewHedgeFunds && selectedFund?.name === 'aggregated_holdings' && assetClasses?.length > 1
);

const GTM_REPORT_TYPE_CONSTANTS = {
  full: {
    reportType: 'Full Report',
    reportName: 'Aggregate and Positions',
  },
  simple: {
    reportType: 'Simple Report',
    reportName: 'Aggregate Only',
  },
  consolidated: {
    reportType: 'Consolidated Report',
    reportName: 'Aggregate Only',
  },
};

export const pdfReportTypes = createSelector(idConfigs, (idConfig) => {
  return Object.keys(idConfig?.pdf_report_types || {}).map((reportType) => ({
    reportType,
    title:
      idConfig[`${reportType}_report_title`] ||
      i18n.t(`investment_dashboard:component.pdf_download_button.${reportType}_report_title`),
    subtitle:
      idConfig[`${reportType}_report_subtitle`] ||
      i18n.t(`investment_dashboard:component.pdf_download_button.${reportType}_report_subtitle`),
    gtmReportType: GTM_REPORT_TYPE_CONSTANTS[reportType].reportType,
    gtmReportName: GTM_REPORT_TYPE_CONSTANTS[reportType].reportName,
  }));
});
